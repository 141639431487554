
// export const myGetter = ( state ) => {
// return state
// }

export const getEntriesByTerm = ( state) => {
    return state.entries
}


export const getSelectedEntry = (state) => {
    return state.selectedEntry;
};


/* export const getEntryById = ( state) => {
    return state.entries
} */


