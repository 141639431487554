import { createStore } from 'vuex'

import adminModule from "../modules/adminpanel/store/adminModule";
import auth from "../modules/auth/store";

const store = createStore({
    modules: {
        adminModule,
        auth
    }
})



export default store

