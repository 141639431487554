<template>
  <PrincipalNavbar/>
  <div class="background">
    <div class="slide" :style="{backgroundImage: 'url(' + images[currentImage] + ')'}"></div>
  </div>
  <div>
    <Services/>
  </div>
  <div id="consultar-certificado">
    <Validation/>
  </div>
  <div>
    <FooterSection/>
  </div>
  <div>
    <FooterCredits/>
  </div>
  <div>
    <WhatsappButton/>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, onMounted } from 'vue';

export default {
  components: {
    PrincipalNavbar: defineAsyncComponent(() => import('../components/PrincipalNavbar.vue')),
    Services: defineAsyncComponent(() => import('../components/Services.vue')),
    FooterSection: defineAsyncComponent(() => import('../components/FooterSection.vue')),
    WhatsappButton: defineAsyncComponent(() => import('../components/WhatsappButton.vue')),
    FooterCredits: defineAsyncComponent(() => import('../components/FooterCredits.vue')),
    Validation: defineAsyncComponent(() => import('../components/Validation.vue')),
  },
  setup() {
    const images = ref([
      require('@/assets/background.png'),
      require('@/assets/backgroundFive.png'),
      require('@/assets/backgroundTwo.png'),
      require('@/assets/backgroundSix.png'),
      require('@/assets/backgroundThree.png'),
      require('@/assets/backgroundSeven.png'),
      require('@/assets/backgroundFour.png'),
    ]);
    const currentImage = ref(0);

    onMounted(() => {
      setInterval(() => {
        currentImage.value = (currentImage.value + 1) % images.value.length;
      }, 5000);
    });

    return { images, currentImage };
  }
}
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}


.background {
  position: relative;
  height: 65vh; 
  width: 100%;
  z-index: 1;
}


.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease;
}
</style>