import axios from 'axios'


const authApi = axios.create({
    baseURL: 'https://identitytoolkit.googleapis.com/v1/accounts',
    params: {
        key: 'AIzaSyAFmhYwRB2K7HSjcyOGm1bkJHAkfMR0UHw'
    }
})


export default authApi