import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import isAuthenticatedGuard from '@/modules/auth/router/auth-guard';

import authRouter from "@/modules/auth/router";
import adminPanelRouter from "@/modules/adminpanel/router";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/auth',
    ...authRouter
  },
  {
    path:'/adminpanel',
    beforeEnter: isAuthenticatedGuard,
    ...adminPanelRouter
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
